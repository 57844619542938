<style lang="scss">
@import '~@/assets/css/int.scss';

.owl-panel {
    position: relative;
    padding: 0 30px;

    .operate {
        position: absolute;
        z-index: 100;
        top: 50%;
        transform: translateY(-50%);
        background: rgba(0, 0, 0, 0.5);
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
        color: #fff;
        cursor: pointer;

        svg {
            transform: scaleX(2);
        }

        &:hover {
            background: rgba(0, 0, 0, 0.8);
        }
    }

    .left {
        left: 45px;
    }

    .right {
        right: 45px;
    }

    .owl-carousel {
        .my-owl-item {
            color: #fff;
            text-align: center;
            cursor: pointer;
            border: 1px solid #ededed;

            .desc {
                padding: 10px;
                background: #fff;
                color: #666;

                .title {
                    font-size: 16px;
                    font-weight: bold;
                    color: black;
                }
            }
        }
    }

    .owl-carousel button.owl-dot {
        height: 10px;
        width: 10px;
        background: #ccc;
        display: inline-block;
        margin: 0 3px;
        border-radius: 50%;
        transition: all .4s;
        backface-visibility: hidden;

        &.active {
            width: 40px;
            border-radius: 10px;
            background: #036011;
        }
    }

    .owl-dots {
        text-align: center;
    }
}
.owl-panel .owl-carousel .my-owl-item .desc .title {
    height: 48px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.home {
    .img-list {
        display: flex;
        flex-wrap: wrap;

        .col-md-6,
        .col-md-12 {
            padding: 0
        }

        .left,
        .right {
            img {
                width: 100%;
                height: 100%;
                padding-bottom: 2.5rem;
            }

            .img {
                position: relative;

                // margin-bottom: 2.5rem;
                .btn {
                    position: absolute;
                    bottom: 1.25rem;
                    left: 50%;
                    transform: translateX(-50%);
                    font-size: 1.125rem;
                    width: 80%;
                    font-weight: bold;
                    box-shadow: 0 0 5px 1px #666;
                }
            }
        }

        .left {
            height: 100%;

            .img {
                height: 100%;
            }
        }

        .right {
            .img {
                padding-left: 0;
            }

            /* 超过720px 就执行下面这段 */
            @media screen and (min-width: 720px) {
                .img {
                    padding-left: 1.25rem;
                }
            }
        }
    }

    .img-list__bottom {
        margin-top: 1.25rem !important;

        .left,
        .right {
            .img {
                padding-left: 0;

                img {
                    width: 100%;
                    height: 100%;
                    padding-bottom: 0;
                }
            }

        }
    }

    .img {
        img {
            width: 100%;
            height: 100%;
        }
    }
}
</style>

<template>
    <div class="home">
        <b-carousel id="carousel-1" v-model="slide" :interval="4000" controls indicators background="#ababab"
            img-width="1024" img-height="480" style="text-shadow: 1px 1px 2px #333;">
            <b-carousel-slide v-for="item in bannerList" :key="item.id" :img-src="item.imgUrl"
                              @click.native="bannerGo(item)"></b-carousel-slide>

        </b-carousel>

        <b-row class="img-list" style="margin:30px 30px 0" v-if="banner1.length > 0">
            <b-col cols="12" md="6" sm="12">
                <div class="left">
                    <div class="img" @click="bannerGo(banner1First)">
                        <img :src="banner1First.imgUrl">
                        <b-button pill variant="light">{{banner1First.title}}</b-button>
                    </div>
                </div>
            </b-col>
            <b-col cols="12" md="6" sm="12">
                <div class="right">
                    <b-row>
                        <b-col cols="12" md="6" class="img" v-for="item in banner1" :key="item.id" @click="bannerGo(item)">
                            <img :src="item.imgUrl">
                            <b-button pill variant="light">{{item.title}}</b-button>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
        </b-row>

        <p class="head-block">DELPHI Flex Disposable</p>
        <div class="owl-panel">
            <p class="operate left" @click="turnLeft">
                <b-icon-chevron-compact-left />
            </p>
            <p class="operate right" @click="turnright">
                <b-icon-chevron-compact-right />
            </p>
            <div id="carousel1" class="owl-carousel">
                <div v-for="item in goodsList" :key="item.id" class="my-owl-item" @click="goDetail(item)">
                    <img :src="item.listImg" alt="" />
                    <div class="desc">
                        <div class="title">
                            {{item.title}}
                        </div>
                        <div class="money">
                            {{item.price}}
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <b-row class="img-list" style="margin:30px" v-if="banner2.length > 0">
            <b-col cols="12" md="6" sm="12">
                <div class="left">
                    <div class="img" @click="bannerGo(banner2First)">
                        <img :src="banner2First.imgUrl" style="padding-bottom: 0;">
                    </div>
                </div>
            </b-col>
            <b-col cols="12" md="6" sm="12">
                <div class="right">
                    <b-row v-for="item in banner2" :key="item.id" @click="bannerGo(item)">
                        <b-col cols="24" md="12" class="img" style="padding:0">
                            <img :src="item.imgUrl" style="padding-bottom: 0;">
                        </b-col>
                    </b-row>
                </div>
            </b-col>
        </b-row>


        <my-foot></my-foot>
    </div>
</template>

<script>
const $ = window.$
let owl = null
export default {
    data() {
        return {
            slide: 0,
            bannerList: [],
            goodsList: [],

            banner1First: {},
            banner1: [],

            banner2First: {},
            banner2: [],
        }
    },  
    mounted() {
        this.getBannerData()
        this.getGoodsList()
    },
    methods: {
        turnLeft() {
            owl.trigger('prev.owl.carousel')
        },
        turnright() {
            owl.trigger('next.owl.carousel')
        },
        getBannerData() {
            this.$api.post('cmsBanner/queryCmsBannerList',  {params: {bannerCategory: 0}})
                .then(res => {
                    this.bannerList = res
                })
            this.$api.post('cmsBanner/queryCmsBannerList',  {params: {bannerCategory: 1}})
                .then(res => {
                    if (res.length > 4) {
                        this.banner1 = res.splice(0, 5)
                        this.banner1First = this.banner1.splice(0, 1)[0]
                    }
                })
            this.$api.post('cmsBanner/queryCmsBannerList',  {params: {bannerCategory: 2}})
                .then(res => {
                    if (res.length > 2) {
                        this.banner2 = res.splice(0, 3)
                        this.banner2First = this.banner2.splice(0, 1)[0]
                    }
                })
        },
        getGoodsList() {
            this.$api.post('cmsProduct/queryCmsProductPage')
                .then(res => {
                    this.goodsList = res.records

                    this.$nextTick(() => {

                        $(document).ready(function () {
                            owl = $("#carousel1").owlCarousel({
                                margin: 30,
                                loop: true,
                                responsive: {
                                    0: {
                                        items: 1
                                    },
                                    600: {
                                        items: 3
                                    },
                                }
                            });
                        });
                    })
                })
        },
        goDetail(data) {
            this.$router.push({path: '/shopdetail', query: {
                text: data.categoryName,
                href: `#/shoplist?name=${data.categoryName}&id=${data.categoryId}`,
                id: data.id,
                name: data.title
            }})
        },
        bannerGo(banner) {
            window.open(banner.herfUrl)
        }
    }
}
</script>