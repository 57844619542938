<style lang="scss">
    @import '~@/assets/css/int.scss';

    .head-block {
        text-align: center;
        clear: both;
        padding: 30px 0;
        font-size: 2rem;
        line-height: 3rem;
        font-weight: bold;
        color: #000;
        position: relative;
    }

    #app {
        .bg-info {
            background-color: $main  !important;
        }

        .app-container {
            height: calc(100vh - 56px);
            overflow-y: scroll;
        }

        .btn {
            &-primary {
                background-color: $main;
                border-color: $main;
            }
        }

        .row {
            margin: 0;
        }

        // @media screen and (min-width: 720px) {
        //     .breadcrumb {
        //         display: none;
        //     }
        // }

        .breadcrumb {
            margin-bottom: 0;

            &-item {
                color: $main;
            }

            .active {
                color: #6c757d;
            }
        }
    }

    .modal-loading {
        background-color: transparent !important;
        border: 0 !important;
        text-align: center;
    }

    .operate-back {
        display: none;
        position: fixed;
        z-index: 100;
        top: 50%;
        width: 350px;
        left: 50%;
        background: black;
        opacity: .8;
        color: #fff;
        padding: 30px;
        text-align: center;
        transform: translate(-50%, -50%);

        .title {
            font-size: 15px;
        }
        i {
            font-size: 100px;
            margin: 15px 0;
            font-weight: bold;
        }
        .desc {
            width: 100%;
            font-size: 14px;
            word-break: break-all;
        }

        .el-icon-check {
            color: #67c23a;
        }
        .el-icon-close {
            color: #f56c6c;
        }

        &.show {
            display: block;
        }
    }
</style>

<template>
    <div id="app">
        <b-navbar toggleable="lg" type="dark" variant="info">
            <b-navbar-brand href="#" style="padding: 0">
                <img src="@/assets/img/logo.webp" style="height: 70px" alt="">
            </b-navbar-brand>

            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav>
                    <b-nav-item href="#/">HOME</b-nav-item>
                </b-navbar-nav>


                <b-navbar-nav>
                    <b-nav-item-dropdown text="PRODUCT">
                        <b-dropdown-item v-for="item in navList" :key="item.id" :href="`#/shoplist?name=${item.categoryName}&id=${item.id}`">{{item.categoryName}}</b-dropdown-item>
                    </b-nav-item-dropdown>
                </b-navbar-nav>


                <b-navbar-nav>
                    <b-nav-item href="#/verify">VERIFY PRODUCT</b-nav-item>
                </b-navbar-nav>


                <b-navbar-nav>
                    <b-nav-item href="#/contact">CONTRACT US</b-nav-item>
                    <!-- <b-nav-item-dropdown text="Contact Us">
                        <b-dropdown-item href="#/contact">Contact</b-dropdown-item>
                        <b-dropdown-item href="#/report">Report A Fake</b-dropdown-item>
                        <b-dropdown-item href="#/warranty">Warranty Claim</b-dropdown-item>
                        <b-dropdown-item href="#">Product Warranty Registration</b-dropdown-item>
                        <b-dropdown-item href="#">Submit Return</b-dropdown-item>
                        <b-dropdown-item href="#">Store Location</b-dropdown-item>
                    </b-nav-item-dropdown> -->
                </b-navbar-nav>

                <!-- <b-navbar-nav class="ml-auto">
                    <b-nav-item-dropdown right>
                        <template #text>
                            <b-icon-person-fill></b-icon-person-fill>
                        </template>
                        <b-dropdown-item href="#/login">Sign In</b-dropdown-item>
                    </b-nav-item-dropdown>
                </b-navbar-nav> -->

            </b-collapse>
        </b-navbar>

        <div class="app-container">
            <router-view></router-view>
        </div>

        <b-modal content-class="modal-loading" v-model="loadingModal" centered no-close-on-esc
                 no-close-on-backdrop hide-header hide-footer hide-header-close>
            <b-spinner label="Spinning"></b-spinner>
        </b-modal>

        <div class="operate-back" :class="{show: operate.show}">
            <p class="title">YOUR PRODUCT IS AUTHENTIC</p>
            <i :class="operate.result ? 'el-icon-check' : 'el-icon-close'"></i>
            <p class="desc">{{operate.txt}}</p>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'App',
        data() {
            return {
                loadingModal: false,
                navList: [],

                operate: {
                    show: false,
                    result: true,
                    txt: '',
                },
            }
        },
        created() {
            this.$api.post('cmsCategory/queryCmsCategoryList')
                .then(res => {
                    this.navList = res
                })

            window.toggleShow = this.toggleShow
            window.operateShow = this.operateShow
        },
        methods: {
            toggleShow(flag) {
                this.loadingModal = !!flag
            },

            operateShow(param) {
                this.operate.show = true
                this.operate.result = param.success
                this.operate.txt = param.msg

                setTimeout(() => {
                    this.operate.show = false
                }, 4000)
            },
        }
    }
</script>

