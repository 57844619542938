<template>
    <div class="report-fake">
        <b-breadcrumb :items="items"></b-breadcrumb>
        <div class="container main_container">
            <header class="page-header">
                <p>Report A Fake</p>
            </header>
            <div class="rte">
                <h3>
                    <b>If you have a fake Pop&nbsp; Disposable,&nbsp; you may experience
                        the following:&nbsp;</b>
                </h3>
                <ul>
                    <li>Low battery life. Some fake devices only last a few hours.</li>
                    <li>Many fake devices have weak nicotine levels.</li>
                    <li>Many fake devices feature diluted or inconsistent flavors. The product may overheat. </li>
                    <li>It may be difficult to get the airflow from the mouthpiece.</li>
                </ul>
                <p>
                    Are you experiencing these problems with a device labeled as “DELPHI”
                    device? Report the product to us immediately!&nbsp; You have probably
                    bought a fake one. Send an email to support@popvapor.com together with
                    pictures of the product and the name of the store where you bought it.
                </p>
                <div class="wpb_text_column wpb_content_element">
                    <div class="wpb_wrapper">
                        <h3><strong>Email Us: support@popvapor.com</strong></h3>
                        <p>&nbsp;</p>
                    </div>
                </div>
            </div>

            <div class="my-form">
                <b-form @submit="onSubmit" @reset="onReset">
                    <b-form-group id="input-group-1" label="Full Name*" label-for="input-1">
                        <b-form-input id="input-1" :state="validationName" v-model="form.name"></b-form-input>
                        <b-form-invalid-feedback :state="validationName">
                            Please enter full name
                        </b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group id="input-group-2" label="Email*" label-for="input-2">
                        <b-form-input id="input-2" v-model="form.email" :state="validationEmail" ></b-form-input>
                        <b-form-invalid-feedback :state="validationEmail">
                            Please enter your e-mail
                        </b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group id="input-group-3" label="Contact Number" label-for="input-3">
                        <b-form-input id="input-3" v-model="form.phone"></b-form-input>
                    </b-form-group>
                    <b-form-group id="input-group-4" label="Name and Address of the location of where you bought the fake from" label-for="input-4">
                        <b-form-input id="input-4" v-model="form.address"></b-form-input>
                    </b-form-group>
                     <b-form-group id="input-group-5" label="Addition Informatio" label-for="input-5">
                        <!-- <b-form-input id="input-5" v-model="form.info"  required></b-form-input> -->
                        <b-form-textarea id="nput-5" v-model="form.info"></b-form-textarea>
                    </b-form-group>
                    <b-button type="submit" block  variant="primary">Submit</b-button>
                </b-form>
            </div>
        </div>
        <my-foot></my-foot>
    </div>
</template>

<script>
export default {
    name: "",
    data() {
        return {
            form: {
                email: '',
                name: '',
                phone:'',
                address: '',
                info: ''
            },
            items: [
                {
                    text: 'Home',
                    href: '/'
                },
                {
                    text: 'Report A Fake',
                    active: true
                },
            ]
            // foods: [{ text: 'Select One', value: null }, 'Carrots', 'Beans', 'Tomatoes', 'Corn'],
        };
    },
    computed: {
        validationName() {
            return this.form.name.length > 0
        },
        validationEmail() {
            let email = /^\w+@[a-z0-9]+\.[a-z]+$/i
            return email.test(this.form.email)
        }
    },
    methods: {
        onSubmit(evt) {
            evt.preventDefault()
            let email = /^\w+@[a-z0-9]+\.[a-z]+$/i
            if(!(this.form.name.length > 0 && email.test(this.form.email))){
                return 
            }
            console.log(this.form)
        },
        onReset(evt) {
            evt.preventDefault()
            // Reset our form values
            this.form.email = ''
            this.form.name = ''
            this.form.food = null
            this.form.checked = []
            // Trick to reset/clear native browser form validation state
            this.show = false
            this.$nextTick(() => {
            this.show = true
            })
        }
    },
    mounted() { },
};
</script>
<style lang='scss'>
@import '~@/assets/css/int.scss';

.report-fake {
    .container {
        padding: 1.25rem .9375rem 0;
        .my-form{
            border: 1px solid #ededed;
            padding: 1.25rem;
            .btn {
                max-width: 30rem;
                margin: 0 auto;
            }
        }
    }
}
</style>