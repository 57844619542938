import Vue from 'vue'
import App from './App.vue'
import router from './config/router'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'bootstrap/dist/css/bootstrap.css'
import './assets/css/reset.scss'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(ElementUI);
import api from './config/api'
Vue.prototype.$api = api

import help from './config/help'
Vue.prototype.$help = help

import variable from './config/variable'
Vue.prototype.$variable = variable

import MyFoot from '@/components/MyFoot'
Vue.component('MyFoot', MyFoot)

Vue.config.productionTip = false

function imgInvert(img = '') {
  if (img.indexOf(variable.uploadSplitStr)> -1) {
    return img.split(variable.uploadSplitStr)[0]
  }
  return img
}
Vue.prototype.getFile = function(key) {
  return key ? `/${variable.apiPrefix}sys/attachment/viewAttachmentStream?attachmentId=${imgInvert(key)}` : ''
}

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
