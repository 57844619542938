<template>
    <div class="verify-product ">
        <div class="container">
            <div class="img">
                <img :src="imgUrl" alt="" />
            </div>
            <div class="title">
                <h1>Verify Product</h1>
                <h3>
                    Find our authenticity sticker on the back of the Delphi Vape product packaging.
                </h3>
            </div>
            <div class="check">
                <b-form-input v-model="value" placeholder="Serial"></b-form-input>
                <b-button pill variant="primary" @click="handleVerify">CHECK</b-button>
            </div>
            <b-alert :show="error" variant="danger" dismissible>{{errorMsg}}</b-alert>
            <b-alert :show="success" variant="success" dismissible>{{successMsg}}</b-alert>
            <div class="wpb_wrapper">
                <div class="wpb_text_column wpb_content_element ">
                    <div class="wpb_wrapper">
                        <h4 style="text-align: left;"><b>Beware of Fake Delphi Vape devices:</b><br>
                            <b>Find out if your Delphi Vape device is authentic&nbsp;</b>
                        </h4>
                        <p style="text-align: left;">Delphi Vape devices are wildly popular, thanks to our
                            diverse flavors, conve­nience, and quality, and we thank our many loyal customers!
                            However, we need you to be aware that counterfeit products are entering the market.</p>
                        <p style="text-align: left;">Counterfeit products are NOT TESTED for safety, and may contain
                            harmful ingredients. Also, many counterfeits don’t provide a full, genuine flavor and
                            provide only a few puffs before running out of juice.</p>
                        <p style="text-align: left;">There’s no way to trust a clone of a real Delphi product. This
                            in-depth guide was creat­ed to help our consumers identify fake products. We recommend
                            buying directly from our website or from an authorized dealer.</p>
                        <h4 style="text-align: left;"><b>THERE ARE FOUR MAIN CRITERIA USED FOR
                                VERIFICATION&nbsp;</b></h4>
                        <p style="text-align: left;"><b>Authentication Code&nbsp;</b></p>
                        <p style="text-align: left;">Each of Delphi Vape device is sealed with an authenticator
                            label you can scratch off.</p>
                        <p style="text-align: left;">The best way to avoid using counterfeit products is to verify
                            your Delphi Vape device ,</p>
                        <p style="text-align: left;">using the authentication code found on the box.</p>
                        <h4 style="text-align: left;"><b>Color&nbsp;</b></h4>
                        <p style="text-align: left;">Each of our Delphi Vape device&nbsp; comes in a package that
                            features a color gradient design.</p>
                        <p style="text-align: left;">If the gradient appears pixelated, or if the design deviates
                            from our official packaging design,</p>
                        <p style="text-align: left;">it usually means the Delphi Vape device  is fake.</p>
                        <h4 style="text-align: left;"><b>Font&nbsp;</b></h4>
                        <p style="text-align: left;">Any font deviations on the back or side of the box may indicate
                            a fake Delphi Vape device .</p>
                        <p style="text-align: left;">The font should be consistent all over the box and there should
                            be no obvious deviations in boldness or size.</p>
                        <h4 style="text-align: left;"><b>Juice</b></h4>
                        <p style="text-align: left;">If, while using a Delphi Vape device , the juice tastes harsh
                            and the vapor production isn’t smooth, the item is probably fake. In this case, we
                            recommend that you stop using the item immediately and return it back to the place you
                            bought it.</p>
                        <h4 style="text-align: left;"><b>HOW DO YOU KNOW IF YOUR DELPHI VAPE DEVICE IS&nbsp;</b></h4>
                        <h4 style="text-align: left;"><b>REAL OR FAKE?&nbsp;</b></h4>
                        <p style="text-align: left;">Counterfeit and clone production companies do their best to
                            create identical models of the products they copy. Some are able to get close to the
                            mark,but if you know what to look for you can always tell the difference. What should
                            you keep an eye out for to know if your delphi vape device  is real or fake?</p>
                        <p style="text-align: left;">Be on the lookout when you head to the authorized dealer  to purchase a Delphi Vape  device. First of all, each Delphi Vape device has four
                            separate parts to the device and its packaging and display.</p>
                        <p style="text-align: left;">If possible, look for all four of the following:</p>
                        <p style="text-align: left;">10-pack display box (if possible)</p>
                        <p style="text-align: left;">Box packaging of the device</p>
                        <p style="text-align: left;">Device wrapper</p>
                        <p style="text-align: left;">Device</p>
                        <p style="text-align: left;">Official Delphi Vape device  devices come in these three
                            separate pieces of packaging, from the display box to the device packaging to the device
                            wrapper. Each piece has unique aspects to look for to know if your Delphi Vape device
                            is real or fake.</p>
                        <h4 style="text-align: left;"><b>DEVICE PACKAGING&nbsp;</b></h4>
                        <p style="text-align: left;">Each Delphi  device comes packaged in a small,
                            rectangular box. These boxes are essentially smaller versions of the 10-pack display
                            case.</p>
                        <p style="text-align: left;">The name of the flavor is displayed prominently on the front of
                            the box alongside an image of the Delphi Vape device contained inside the box.
                            The device image should be the correct color that corresponds with the flavor. It should
                            also have the Delphi Vape logo, the name of the flavor, and the corresponding
                            nicotine level pictured on it.</p>
                        <p style="text-align: left;">The back of the box contains information about Delphi Vape device  along with instructions for use. There will be a bar-code around the middle
                            of the back as well as another nicotine warning label along the bottom. Also, look for a
                            stamp with a scratch-away area revealing a code to confirm your device’s authenticity.
                        </p>
                        <h4 style="text-align: left;"><b>DEVICE WRAPPER</b></h4>
                        <p style="text-align: left;">Once you open an individual Delphi Vape product box, the
                            device itself is contained within a tearaway wrapper. The wrapper should be the correct
                            color that corresponds with the bar’s flavor. It will also have the Delphi Vape logo and
                            the flavor.</p>
                        <h4 style="text-align: left;"><b>DEVICE&nbsp;</b></h4>
                        <p style="text-align: left;">Each  Delphi Vape device flavor has a unique color associated
                            with it. The bar should be the correct color, matching the wrapper and box that it came
                            in.</p>
                        <p style="text-align: left;"><b>If you have a fake Delphi Vape device ,&nbsp; you may
                                experience the following:&nbsp;</b></p>
                        <ul style="text-align: left;">
                            <li>Low battery life. Some fake devices only last a few hours.</li>
                            <li>Many fake devices have weak nicotine levels.</li>
                            <li>Many fake devices feature diluted or inconsistent flavors. The product may overheat.
                            </li>
                            <li>It may be difficult to get the airflow from the mouthpiece.</li>
                        </ul>
                        <p style="text-align: left;">Are you experiencing these problems with a device labeled as
                            “Delphi Vape” device? Report the product to us immediately!&nbsp; You have probably bought a
                            fake one. Send an email to zeddelphi@gmail.com together with pictures of the product
                            and the name of the store where you bought it.</p>

                    </div>
                </div>
            </div>
        </div>
        <my-foot></my-foot>
    </div>
</template>

<script>
export default {
    name: "",
    data() {
        return {
            imgUrl: require("@/assets/img/logo.webp"),
            value: "",
            error: false,
            errorMsg: '',

            successMsg: "it's real",
            success: false,
        };
    },
    methods: {
        handleVerify() {
            this.error = false
            if (this.value) {
                this.$help.loading()
                this.$api.get('cmsProductCode/findCmsProductCodeByProductCode', {
                    productCode: this.value
                })
                    .then(() => {
                        window.operateShow({
                            success: true,
                            msg: 'VERIFY SUCCESS',
                        })
                    })
                    .catch(res => {
                        window.operateShow({
                            success: false,
                            msg: res.message,
                        })
                    })
                    .finally(this.$help.loaded)
            }
        },
    },
};
</script>
<style lang='scss'>
@import "~@/assets/css/int.scss";

.verify-product {
    color: black;
    .img {
        margin-top: 50px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 20rem;
            height: 20rem;
        }
    }
    .title {
        margin: 50px 0 30px;
        text-align: center;
        h1 {
            margin: 30px 0;
            font-size: 50px;
        }
    }
    .check {
        display: flex;
        margin-bottom: 20px;
        .btn {
            margin-left: 20px;
        }
    }
    .wpb_wrapper{
        h4{
            margin: 1.875rem 0  .6rem;
            font-size: 1.875rem;
        }
    }
}
</style>