<template>
    <div>
        login
    </div>
</template>

<script>
export default {
    name:'',
    data () {
        return {

        };
    },
    methods: {},
    mounted() {},
   }
</script>
<style lang='scss'>

</style>