<template>
    <div class="my-foot">
        <div class="introduce">
            <div class="sub-heading" style="color: #ffffff">
                <strong>NOT FOR SALE TO MINORS - </strong>Products sold on this site may contain nicotine which is a highly addictive substance<br /><strong>CALIFORNIA PROPOSITION 65 - WARNING: </strong>This product can expose you to chemicals including nicotine, which is known to the State of California to cause birth defects or other reproductive harm. For more information, go to Proposition 65 Warnings Website. Products sold on this site is intended for adult smokers. You must be of legal smoking age in your territory to purchase products. Please consult your physician before use. E-Juice on our site may contain Propylene Glycol and/or Vegetable Glycerin, Nicotine and Flavorings. Our products may be poisonous if orally ingested. Products sold by Pop Vapor are not smoking cessation products and have not been evaluated by the Food and Drug Administration, nor are they intended to treat, prevent or cure any disease or condition. For their protection, please keep out of reach of children and pets. Read our terms and conditions page before purchasing our products. Use All Products On This Site At Your Own Risk!
            </div>
        </div>
        <!-- <div class="nav-bottom">
            <b-row>
                <b-col md="12/(navList.length)" sm="12/(navList.length)" v-for="parent in navList" :key="parent.title">
                    <div class="left">
                        <div  class="item">
                            <div class="title">
                                {{ parent.title }}
                            </div>
                            <div class="link" v-for="child in parent.linkList" :key="child.name">
                                {{ child.name }}
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <div class="right">
                <div class="email">
                    <div class="title">
                        NEWSLETTER
                    </div>
                    <div class="p">Subscribe now to get daily updates</div>
                    <div class="subscribe">
                        <b-form-input v-model="email" placeholder="Enter Your Email Address"></b-form-input>
                        <b-button pill variant="primary">subscribe</b-button>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="bottom">
            Copyright © 2022 Delphi Vape, All Rights Reserved.
        </div>
    </div>
</template>

<script>
export default {
    name: "",
    data() {
        return {
            navList: [
                {
                    title: 'LEARN MORE', linkList: [
                        {
                            name: 'Shipping Information', path: ''
                        },
                        {
                            name: 'Return/Refund Policy', path: ''
                        },
                        {
                            name: 'Privacy Policy', path: ''
                        }
                    ]
                },
                {
                    title: 'SUPPORT', linkList: [
                        {
                            name: 'Contact Us', path: ''
                        },
                        {
                            name: 'Wholesale', path: ''
                        },
                        {
                            name: 'Faq’s', path: ''
                        }
                    ]
                }
            ],
            email: ""
        };
    },
    methods: {},
    mounted() { },
};
</script>
<style lang='scss'>
@import '~@/assets/css/int.scss';

.my-foot {
    margin-top: 1.25rem;
    .introduce {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 300px;
        background-color: #212121;
        .sub-heading {
            padding: 1.25rem;
            text-align: center;
        }
    }
    .row {
        margin: 0;
        padding-left: 1.25rem;
    }
    .nav-bottom {
        background-color: #f5f5f5;
        padding: 2.5rem 0;
        position: relative;
        z-index: 1;
        line-height: 1.5rem;
        display: flex;
        flex-wrap: wrap;
        .left {
            // display: flex;
            // flex-wrap: wrap;
            .item {
                min-width: 12.5rem;
                margin-right: 1.25rem;
                margin-bottom: 1.25rem;

                .link {
                    line-height: 2rem;
                    &:hover {
                        color: $main;
                    }
                }
            }
        }

        .left, .right {
            .title {
                color: black;
                font-size: 1rem;
                font-weight: bold;
            }
        }

        .right {
            padding: 0 .9375rem;
            flex: 1;
            .email {
                .p {
                    padding: .625rem 0;
                }
                .subscribe {
                    .btn {
                        margin-top: .625rem;
                    }
                }
            }
        }
    }
    .bottom{
        text-align: center;
        padding: 1.25rem 0;
    }
}
</style>