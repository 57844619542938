import Vue from 'vue'
import VueRouter from 'vue-router'

import pageIndex from '@/pages/Index'
import pageContactUs from '@/pages/ContactUs'
import ReportFake from '@/pages/ReportFake'
import WarrantyClain from '@/pages/WarrantyClain'
import List from '@/pages/shop/list'
import Defail from '@/pages/shop/detail'
import Login from '@/pages/login'
import Verify from '@/pages/Verify'

Vue.use(VueRouter)

const routes = [
    {path: '/', component: pageIndex},
    {path: '/contact', component: pageContactUs},
    {path: '/report', component: ReportFake},
    {path: '/warranty', component: WarrantyClain},
    {path: '/shoplist', component: List},
    {path: '/shopdetail', component: Defail},
    {path: '/login', component: Login},
    {path: '/verify', component: Verify},
]

const router = new VueRouter({
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { left: 0, top: 0 }
        }
    },
})
router.afterEach(() => {
    const el = document.querySelector('.app-container')
    if (el && el.scrollTo) {
        el.scrollTo(0, 0)
    }
});

export default router