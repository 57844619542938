<template>
    <div class="contact">
        <b-breadcrumb :items="items"></b-breadcrumb>
        <div class="container">
            <div class="contactFormWrapper">
                <h3 class="title" style="text-align: center;">Get In Touch</h3>
                <b-row class="left-right">
                    <b-col cols="12" md="6" sm="12">
                        <div class="item left">
                            <b-row class="input-list">
                                <b-col cols="12" md="12" class="input" >
                                    <b-form-input v-model="form.name" placeholder="Name"></b-form-input>
                                </b-col>
                                <b-col cols="12" md="12" class="input" >
                                    <b-form-input v-model="form.email" placeholder="Email"></b-form-input>
                                </b-col>
                                <b-col cols="12" md="12" class="input" >
                                    <b-form-input v-model="form.phone" placeholder="Your Phone"></b-form-input>
                                </b-col>
                            </b-row>
                        </div>
                    </b-col>
                    <b-col cols="12" md="6" sm="12">
                        <div class="item right">
                            <b-form-textarea
                                placeholder="Your Comment"
                                v-model="form.comment"
                            ></b-form-textarea>
                        </div>
                    </b-col>
                </b-row>
                <div class="button">
                    <b-button squared variant="primary" @click="onSave">SEND MESSAGE</b-button>
                </div>
            </div>
        </div>
        <my-foot></my-foot>
    </div>
</template>

<script>

export default {
    data() {
        return {
            items: [
                {
                    text: 'Home',
                    href: '/'
                },
                {
                    text: 'CONTACT US',
                    active: true
                },
            ],
            form: {
                name: '',
                email: '',
                phone: '',
                comment: ''
            },
            info: {},
        }
    },
    created() {
        this.$api.get('cmsContract/queryContract')
            .then(res => {
                this.info = res
            })
    },
    methods: {
        onSave() {
            // console.log(this.form)
            this.$help.loading()
            this.$api.post('cmsContract/insertCmsContract', this.form).then(()=>{
                this.form = {}
                window.operateShow({
                    success: true,
                    msg: 'CONTACT SUCCESS',
                })
            })
            .catch(res=>{
                window.operateShow({
                    success: false,
                    msg: res.message,
                })
            })
            .finally(()=>{
                this.$help.loaded()
            })
        }
    }
};
</script>

<style lang="scss">
    @import '~@/assets/css/int.scss';
    .contact {
        .tel {
            padding: 2.5rem 0;
            .item {
                margin-bottom: 1.25rem;
            }
        }
        .contactFormWrapper{
            padding:  2.5rem 0;
            .title{
                padding-bottom: 2.5rem;
            }
            .left-right{
                .right{
                    height: 100%;
                    .form-control{
                        min-height: 9.6875rem;    
                        max-height: 9.6875rem;    
                    }
                }
            }
            .input-list {
                .col-md-12{
                    padding: 0;
                }
                .input {
                    margin-bottom: 1.25rem;
                }
            }
            .button{
                display: flex;
                justify-content: center;
                margin-top: 1.25rem;
            }
            
        }
        .alert{
            position: fixed;
            top: 30px;
            left: 50%;
            transform: translateX(-50%);
            width: 20rem;
            text-align: center;
        }
    }
</style>