<template>
    <div class="shop-detail">
        <b-breadcrumb :items="items"></b-breadcrumb>
        <div class="content container">
            <b-row>
                <b-col cols="12" md="6" sm="12">
                    <div class="img">
                        <el-carousel height="350px" :indicator-position="false">
                            <el-carousel-item v-for="item in imgList" :key="item">
                                <img style="height: 350px; object-fit: contain"
                                    :src="item">
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                </b-col>
                <b-col cols="12" md="6" sm="12">
                    <div class="product-shop">
                        <div itemprop="offers" class="shop-detail-content">
                            <div class="fixed-product-wr">
                                <header class="product-title has-btn">
                                    <h1 itemprop="name">
                                        {{info.title}}
                                    </h1>
                                </header>
                                <div class="prodcuct-vendor">
                                    Vendor: <span> {{info.vendor}}</span>
                                </div>
                                <div class="product-sku">
                                    SKU: <span class="variant-sku">{{info.sku}}</span>
                                </div>
                                <div class="prices">${{info.price}}</div>
                            </div>
                            <div class="product-visitors">
                                <!-- <div class="visitors auto-visitors">
                                    <i class="cs-font clever-icon-team"></i> Real Time
                                    <span class="visitors-text" data-js-counter="">21</span>
                                    Visitor Right Now
                                </div> -->
                            </div>
                        </div>
                        <div class="product_meta">
                            <p>
                                Categories:
                                <a href="#"> {{info.categoryName}} </a>
                            </p>
                            <p>
                                Tags: <span>{{info.tags}}</span>
                            </p>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <div class="product-tabs">
                <div class="title">
                    <span>Description</span>
                </div>
                <div class="tab-content">
                    <p>
                       {{info.content}}
                    </p>
                </div>
            </div>
            <!-- <div class="related-heading">
                <h2>Related Products</h2>
            </div>
            <div class="owl-panel">
                <p class="operate left" @click="turnLeft">
                    <b-icon-chevron-compact-left />
                </p>
                <p class="operate right" @click="turnright">
                    <b-icon-chevron-compact-right />
                </p>
                <div id="carousel1" class="owl-carousel">
                    <div v-for="item in 10" :key="item" class="my-owl-item">
                        <img src="//cdn.shopify.com/s/files/1/0571/2799/3404/products/Pop-Flex-Tropical-Punch.jpg?v=1654033006"
                            alt="">
                            <div class="desc">
                                <div class="title">
                                    Pop Flex Disposable Device – Iced Mango Berries
                                </div>
                                <div class="money">
                                    $13.99
                                </div>
                            </div>
                    </div>
                </div>
            </div> -->
        </div>
        <my-foot></my-foot>
    </div>
</template>

<script>
export default {
    name: "",
    data() {
        return {
            items: [
                {
                    text: "Home",
                    href: "/",
                },
            ],
            info:{},
            imgList: [],
        };
    },
    watch: {
        $route() {
            this.changeTitle();
        },
    },
    created() {
        this.changeTitle();
    },
    methods: {
        changeTitle() {
            this.items = [
                {
                    text: "Home",
                    href: "/",
                },
            ];
            this.items.push({
                text: this.$route.query.text,
                href: this.$route.query.href,
            });
            this.items.push({
                text: this.$route.query.name,
                active: true,
            });
        },
        getDetail() {
            this.$help.loading()
            this.$api.post('cmsProduct/findCmsProductById', {id: this.$route.query.id}).then(res=>{
                this.info = res
                if (res.detailImg) {
                    this.imgList = res.detailImg.split(',').map(item => {
                        if (item) {
                            const arr = item.split(this.$variable.uploadSplitStr)
                            return arr[0]
                        } else {
                            return ''
                        }
                    })
                }
            })
            .finally(this.$help.loaded)
        }
    },
    mounted() {
        // console.log(this.$route.query)
        this.getDetail()
    },
};
</script>
<style lang='scss'>
@import "~@/assets/css/int.scss";

.shop-detail {
    .container {
        padding: 0
    }

    .content {
        padding: 50px 0 30px;
        .product-shop {
            .product-title {
                margin-bottom: 30px;
            }

            .prices {
                font-size: 30px;
                font-weight: bold;
                color: #666;
                margin: 10px 0 30px;
            }


        }

        .img {
            img {
                height: 100%;
                width: 100%;
            }
        }

        .product-tabs {
            margin-top: 30px;
            padding: 0 15px;

            .title {
                border-bottom: 1px solid #ededed;
                padding: 10px 0;
            }

            .tab-content {
                padding: 20px 0;
            }
        }

        @media screen and (max-width: 720px) {

            .col-sm-12,
            .col-md-6,
            .col-12 {
                padding: 0;
            }

            .product-shop {
                padding: 0 15px;
            }
        }

        .related-heading {
            text-align: center;
            border-bottom: 1px solid #ededed;
            margin: 30px 0;
        }

        .owl-panel {
            position: relative;
            padding: 0 30px;

            .operate {
                position: absolute;
                z-index: 100;
                top: 50%;
                transform: translateY(-50%);
                background: rgba(0, 0, 0, 0.5);
                border-radius: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 36px;
                height: 36px;
                color: #fff;
                cursor: pointer;

                svg {
                    transform: scaleX(2);
                }

                &:hover {
                    background: rgba(0, 0, 0, 0.8);
                }
            }

            .left {
                left: 45px;
            }

            .right {
                right: 45px;
            }

            .owl-carousel {
                .my-owl-item {
                    color: #fff;
                    background: #000;
                    text-align: center;
                    cursor: pointer;
                    border: 1px solid #ededed;
                    .desc{
                        padding: 10px;
                        background: #fff;
                        color: #666;
                        .title {
                            font-size: 16px;
                            font-weight: bold;
                            color:black;
                        }
                    }
                }
            }

            .owl-carousel button.owl-dot {
                height: 10px;
                width: 10px;
                background: #ccc;
                display: inline-block;
                margin: 0 3px;
                border-radius: 50%;
                transition: all .4s;
                backface-visibility: hidden;

                &.active {
                    width: 40px;
                    border-radius: 10px;
                    background: #036011;
                }
            }

            .owl-dots {
                text-align: center;
            }
        }
    }
}
</style>