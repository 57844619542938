import axios from 'axios'
import variable from './variable'

let myAxios = axios.create({
    baseURL: '/',
    responseType: 'json',
    withCredentials: true,
    headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': "application/json;charset=UTF-8",
    },
})

myAxios.interceptors.request.use(function (config) {
    return config;
}, function (error) {
    return Promise.reject(error);
})

function doError(res, reject) {
    reject(res)
}

function requestFunc(func, url, query, headers = {}) {
    return new Promise((resolve, reject) => {
        let param1 = {},
            param2 = {}
        if (['get', 'delete'].includes(func)) {
            param1 = {params: query, headers}
        } else {
            param1 = query
            param2 = {headers}
        }
        myAxios[func](`${variable.apiPrefix}${url}`, param1, param2)
            .then(data => {
                const res = data.data
                if (res.code === '00000') {
                    resolve(res.data)
                } else {
                    doError(res, reject)
                }
            })
            .catch(res => {
                doError(res, reject)
            })
    })
}

export default {
    service: myAxios,
    axios,
    get(url, params, headers) {
        return requestFunc('get', url, params, headers)
    },
    post(url, params = {}, headers = {}) {
        return requestFunc('post', url, params, headers)
    },
    put(url, params = {}, headers = {}) {
        return requestFunc('put', url, params, headers)
    },
    patch(url, params = {}, headers = {}) {
        return requestFunc('patch', url, params, headers)
    },
    delete(url, params = {}, headers = {}) {
        return requestFunc('delete', url, params, headers)
    },
}