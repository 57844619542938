import { render, staticRenderFns } from "./MyFoot.vue?vue&type=template&id=848d044a&"
import script from "./MyFoot.vue?vue&type=script&lang=js&"
export * from "./MyFoot.vue?vue&type=script&lang=js&"
import style0 from "./MyFoot.vue?vue&type=style&index=0&id=848d044a&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports