<template>
    <div class="shop-list">
        <b-breadcrumb :items="items"></b-breadcrumb>
        <div class="content">
            <div class="bav-left__pc left">
                <div v-b-toggle.collapse-1>Collections<b-icon-chevron-down></b-icon-chevron-down></div>
                <b-collapse id="collapse-1" :visible="true" class="mt-2">
                    <div class="list">
                        <div class="item" :class="{active: item.categoryName === categoryName}" v-for="(item, index) in navList" :key="index" @click="onClickNav(item)">{{item.categoryName}}</div>
                    </div>
                </b-collapse>
            </div>
            <div class="right">
                <div class="right_title">
                   <h2> {{categoryName}}</h2>
                   <p> Pop Bar</p>
                </div>
                <div class="right_navbar">
                    <div class="filter">
                        <div class="name" @click="showOverlay=true"><b-icon-list-check></b-icon-list-check>TILTER</div>
                    </div>
                    <div class="way">
                        <div class="name">VIEW AS</div>
                        <div :class="{ 'active': showWay === 'column'}" @click="showWay='column'"><b-icon-grid></b-icon-grid></div>
                        <div class="svg" :class="{ 'active': showWay === 'line'}" @click="showWay='line'"><b-icon-list-check></b-icon-list-check></div>
                    </div>
                    <div class="order">
                        <select class="sort-by" v-model="sortVal" @change="sort">
                            <option v-for="(item, index) in orderList" :key="index" :value="item.keyword">{{item.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="right_goods"  >
                    <div :class="showWay">
                        <div class="item" v-for="(item, i) in goodsList" :key="i" @click="goDetail(item)">
                            <div class="img">
                                <img :src="item.listImg" alt="">
                            </div>
                            <div class="title">
                                <div class="name">
                                    {{item.title}}
                                </div>
                                 <div class="desc">
                                    {{item.content}}
                                </div>
                                <div class="money">
                                    {{item.price | money}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="overflow-auto">
                    <b-pagination  v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        @change="changePage"
                    ></b-pagination>
                </div>
            </div>
        </div> 
       
        <div class="overlay-filter " :class="{ 'overlay-active' : !showOverlay}">
            <div class="overlay-content">
                <div class="overlay-title">
                    <div>FILTER</div>
                    <div @click="showOverlay=false"><b-icon-x></b-icon-x></div>
                </div>
                <div class="overlay-collapse">
                    <div v-b-toggle.collapse-1>Collections<b-icon-chevron-down></b-icon-chevron-down></div>
                    <b-collapse id="collapse-1" :visible="true" class="mt-2">
                        <div class="list">
                            <div class="item" :class="{active: item.categoryName === categoryName}"
                                 v-for="(item, index) in navList" :key="index" @click="onClickNav(item)">{{item.categoryName}}</div>
                        </div>
                    </b-collapse>
                </div>
            </div>
        </div>
        <my-foot></my-foot>
    </div>
</template>

<script>
export default {
    name:'',
    data () {
        return {
            items: [
                {
                    text: 'Home',
                    href: '/'
                },
            ],
            orderList: [
                {name: 'SORT BY FEATURED', keyword: '1'},
                {name: 'SORT BY PRICE: LOW TO HIGH', keyword: '2'},
                {name: 'SORT BY PRICE: HIGH TO LOW', keyword: '3'},  
            ],
            sortVal: 1,
            goodsList: [
                // {name: 'Pop Bar Disposable Device – Banana Ice', title: '', desc: '风味简介：香蕉 IcePop Flex 8 毫升高级一次性设备每个 Pop Flex ', money: '$15.99', imgUrl: 'https://cdn.shopify.com/s/files/1/0571/2799/3404/products/Pop-Bar-Banana-Ice.jpg?v=1653933135', id: '1'},
                // {name: 'Pop Bar Disposable Device – Banana Ice', title: '', desc: '风味简介：香蕉 IcePop Flex 8 毫升高级一次性设备每个 Pop Flex 一次性设备包括：单计数包装网状线圈技术每设备 8 毫升 5% 尼古丁不是来自烟草', money: '$16.99', imgUrl: 'https://cdn.shopify.com/s/files/1/0571/2799/3404/products/Pop-Bar-Bubblegum.jpg?v=1653933890', id: '2'},
                // {name: 'Pop Bar Disposable Device – Banana Ice', title: '', desc: '风味简介：香蕉 IcePop Flex 8 毫升高级一次性设备每个 Pop Flex 一次性设备包括：单计数包装网状线圈技术每设备 8 毫升 5% 尼古丁不是来自烟草', money: '$17.99', imgUrl: 'https://cdn.shopify.com/s/files/1/0571/2799/3404/products/Pop-Flex-Blue-Razz.jpg?v=1654032030', id: '3'},
                // {name: 'Pop Bar Disposable Device – Banana Ice', title: '', desc: '风味简介：香蕉 IcePop Flex 8 毫升高级一次性设备每个 Pop Flex 一次性设备包括：单计数包装网状线圈技术每设备 8 毫升 5% 尼古丁不是来自烟草', money: '$18.99', imgUrl: 'https://cdn.shopify.com/s/files/1/0571/2799/3404/products/Pop-Bar-Cool-Mint.jpg?v=1653934023', id: '4'},
                // {name: 'Pop Bar Disposable Device – Banana Ice', title: '', desc: '风味简介：香蕉 IcePop Flex 8 毫升高级一次性设备每个 Pop Flex 一次性设备包括：单计数包装网状线圈技术每设备 8 毫升 5% 尼古丁不是来自烟草', money: '$19.99', imgUrl: 'https://cdn.shopify.com/s/files/1/0571/2799/3404/products/Pop-Flex-Cool-Mint.jpg?v=1654032190', id: '5'},
            ],
            showWay: 'column',
            categoryId: '',
            categoryName: '',
            showOverlay: false,
            navList: [],
            currentPage: 1,
            perPage: 20,
            rows: 1
        };
    },
    
    watch:{
        $route(){
            this.changeTitle()
        },
    },
    filters: {
        money(val) {
            return '$' + val
        }
    },
    created() {
        this.changeTitle()
        this.$api.post('cmsCategory/queryCmsCategoryList')
        .then(res => {
            this.navList = res
        })
    },
    methods: {
        changeTitle() {
            this.items = [ 
                {
                    text: 'Home',
                    href: '/'
                },
            ]
            this.items.push({
                text: this.$route.query.name,
                active: true
            })
            this.categoryName = this.$route.query.name
            this.categoryId = this.$route.query.id
            this.init()
        },
        goDetail(data) {
            // console.log(data)
            this.$router.push({path: '/shopdetail', query: {
                text: data.categoryName,
                href: `#/shoplist?name=${data.categoryName}&id=${data.categoryId}`,
                id: data.id,
                name: data.title
            }})
        },
        changePage(data) {
            // console.log(data)
            this.currentPage = data
            this.getGoodsList()
        },
        onClickNav(data) {
            this.items = [ 
                {
                    text: 'Home',
                    href: '/'
                },
            ]
            this.items.push({
                text: data.categoryName,
                active: true
            })
            this.categoryName = data.categoryName
            this.categoryId = data.id
            this.init()
            this.showOverlay=false
        },
        init() {
            this.currentPage = 1
            this.getGoodsList()
        },
        sort() {
            this.getGoodsList()
        },
        getGoodsList() {
            this.$help.loading()
            const param = {
                page: { pageNo: this.currentPage, pageSize: this.perPage },
                params:{categoryId: this.categoryId},
            }
            if (this.sortVal === '2') {
                param.sort = {
                    price: 'asc'
                }
            }
            if (this.sortVal === '3') {
                param.sort = {
                    price: 'desc'
                }
            }
            this.$api.post('cmsProduct/queryCmsProductPage', param)
                .then(res => {
                    // console.log(res)
                    this.rows = res.totalCount
                    this.goodsList = res.records
                })
                .finally(this.$help.loaded)
        }
    },
    mounted() {
        // this.getGoodsList()
        // console.log(this.$route.query)
    },
}
</script>
<style lang='scss'>
    @import '~@/assets/css/int.scss';
    .shop-list{
        .content{
            padding: 30px;
            display: flex;
            .left{
                flex: 1;
                min-width: 200px;
                svg{
                    margin-left: 15px;
                }
                .list{
                    .item {
                        line-height: 35px;
                        cursor: pointer;
                        &:hover {
                            color: $main;
                        }
                        &.active {
                            color: $main;
                        }
                    }
                }
            }
            
            .right{
                flex: 5;
                &_navbar{
                    margin-top: 20px;
                    display: flex;
                    justify-content: space-between;
                    padding: 10px;
                    background: #f6f8fa;
                    font-weight: bold;
                    .filter{
                        display: none;
                    }
                    .way{
                        display: flex; 
                        svg{
                            margin-left: 20px;
                            font-size: 20px;
                            color: #999;
                        }
                        .svg{
                            svg{
                                font-size: 25px;
                            }
                        }
                        .active {
                            svg{
                                color: black;
                            }
                        }
                    }
                    .order{
                        select{
                            font-weight: bold;
                            outline: none;
                            border: 0;
                        }
                        option{
                            font-weight: bold;
                        }
                    }
                }
                &_goods{
                    .column{
                        display: flex;
                        flex-wrap: wrap;
                        .item{ 
                            margin-top: 20px;
                            width: 33.3%;
                            border:1px solid #ededed;
                            cursor: pointer;
                            .desc{
                                display: none;
                            }
                            .img{
                                overflow: hidden;
                            }
                            img{
                                width: 100%;
                                height: 100%;
                                // &:hover{
                                //     //transform: scale(1.2);
                                // }
                            }
                            .title{
                                text-align: center;
                                padding: 10px;
                                .name{
                                    font-size: 16px;
                                    font-weight: bold;
                                    padding: 0 0 10px 0;
                                    &:hover{
                                        color: green;
                                    }
                                }
                            }
                            
                        }
                    }
                    .line{
                        .item{ 
                            margin-top: 20px;
                            border: 1px solid #ededed;
                            display: flex;
                            cursor: pointer;
                            .img{
                                flex: 1;
                                img{
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                            .title{
                                flex: 3;
                                padding: 10px 30px 10px;
                                .name {
                                    font-size: 18px;
                                    font-weight: bold;
                                    &:hover{
                                        color: green;
                                    }
                                }
                                .money {
                                    font-size: 16px;
                                    font-weight: bold;
                                }
                                .desc{
                                    display: block !important;
                                    color: #999;
                                    padding: 10px 0;
                                }
                            }
                            
                        }
                    }
                }
                .overflow-auto{
                    margin-top: 50px;
                    display: flex;
                    justify-content: center;
                }
            }
        }
        @media screen and (max-width: 720px) {
            .content{ 
                padding: 20px;
                .bav-left__pc {
                    display: none;
                }
                .right{
                    &_navbar{
                        .way{
                            display: none;
                        }
                        .filter{
                            display: block;
                            svg{
                                font-size: 18px;
                                margin-right: 10px;
                            }
                        }
                        .order{
                            .sort-by{
                                width: 12rem;
                            }
                        }
                    }
                    &_goods{
                        .column{
                            .item{ 
                                width: 50%;
                                .desc{
                                    display: none;
                                }
                            }
                        }
                        .line{
                            .item{ 
                                margin-top: 20px;
                                border: 1px solid #ededed;
                                display: block;
                                cursor: pointer;
                                .title{
                                    padding: 10px;
                                    .name {
                                        font-size: 18px;
                                        font-weight: bold;
                                    }
                                    .money {
                                        font-size: 14px;
                                        font-weight: bold;
                                    }
                                    .desc{
                                        display: block !important;
                                        color: #999;
                                        padding: 10px 0;
                                    }
                                }
                                
                            }
                        }
                    }
                }

            }
        }
        .overlay-filter {
            position: fixed;
            text-align: center;
            background-color: rgba(0, 0, 0, 0.55);
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 10;
            -webkit-transition: 0.5s;
            transition: 0.5s;
            .overlay-content{
                background: #fff;
                height: 100vh;
                width: 80vw;
                .overlay-title{
                    height: 40px;
                    width: 100%;
                    padding: 0 15px;
                    background: $main;
                    color: #fff;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-size: 20px;
                    svg{
                        font-size: 35px;
                    }
                }
                .overlay-collapse{
                    text-align: left;
                    padding: 20px 0 0 20px;
                    svg{
                        margin-left: 15px;
                    }
                    .list{
                        .item {
                            line-height: 35px;
                            &:hover {
                                color: $main;
                            }
                            &.active {
                                color: $main;
                            }
                        }
                    }
                }
            }
        }
        .overlay-active{
            -webkit-transition: 0.5s;
            transition: 0.5s;
            transform: rotateX(90deg);
            -ms-transform: rotateX(90deg);
            -moz-transform: rotateX(90deg);
            -webkit-transform: rotateX(90deg);
        }
    }
</style>