<template>
    <div class="warranty-clain">
        <b-breadcrumb :items="items"></b-breadcrumb>
        <main class="main-content">
            <div class="container main_container">
                <header class="page-header">
                    <h6>Warranty Clain</h6>
                </header>
                <div class="rte">
                    <p class="has-medium-font-size"><strong>File your Warranty Claim</strong></p>
                    <p>At Pop Vapor, We back each device with a lifetime warranty. We enjoy taking care of our customers
                        and stand by our products with an industry leading guarantee.</p>
                    <p>If you’re having issues with your Twist Slim Pen, you’re in luck! Our Pop Vapor warranty program
                        applies to all of our battery operated devices. It does not apply to any product that does not
                        have a battery component.</p>
                    <p>We have two unique warranty options, and which warranty applies to your depends on which device
                        you have. You can find the breakdown of everything covered under the Pop Vapor warranty program
                        here.</p>
                    <p>&nbsp;</p>
                    <p class="has-medium-font-size"><strong>File a claim to redeem your device’s warranty</strong></p>
                    <p>Please fill out the form below and your claim will be evaluated by a Pop Vapor support team
                        member within 72 business hours of receiving your form. You will receive a confirmation email
                        once the claim has been submitted and received by our system. You will then receive a second
                        email approving your claim, denying your claim, or requesting more information.</p>
                    <p>Some claims may require a return before receiving a replacement
                        device<strong>.<span>&nbsp;</span></strong>If this applies to you, we will email you a link
                        for<span>&nbsp;</span><strong>FREE</strong><span>&nbsp;</span>prepaid shipping label. Once we
                        receive your device, our team will evaluate your claim.</p>
                    <p>This process typically take 7-10 business days. Pop Vapor is not liable for items that are
                        damaged or lost in transit to our office. Prepaid labels are only provided to customers in the
                        lower 48 states.</p>
                    <p>Pop Vapor determines that the device is covered by the warranty, a coupon code will be emailed to
                        you. This code allows you to order
                        a<span>&nbsp;</span><strong>FREE<span>&nbsp;</span></strong>replacement device directly from
                        Popvapor.com.</p>
                    <p><em>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; *A Shipping fee of $4.95 will be
                            charged at checkout for customers in the lower 48 states. HI, AK, and international
                            customers may see higher shipping cost.*</em></p>
                    <p>If you purchased your battery at popvapor.com and are having issues with the device 30 days of
                        the delivery date. Send an email to<span>&nbsp;</span><a
                            href="mailto:support@popvapor.com">support@popvapor.com</a><span>&nbsp;</span>immediately.
                    </p>
                    <p>In your email. Explain the issue your battery is having include your order number. Once we
                        verify. We will issue a replacement right away and send you a pre-paid shipping label to send
                        the non-working battery back to us.</p>
                </div>
            </div>
        </main>
        <my-foot></my-foot>
    </div>
</template>

<script>
export default {
    name: '',
    data() {
        return {
            items: [
                {
                    text: 'Home',
                    href: '/'
                },
                {
                    text: 'Warranty Clain',
                    active: true
                },
            ]
        };
    },
    methods: {},
    mounted() { },
}
</script>
<style lang='scss'>
    .warranty-clain{
        .main-content{
            padding-top: 1.25rem;
        }
    }
</style>